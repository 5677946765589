import { Grid, Typography, useTheme } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import { http } from "../../backend/request"
import { Before, Second, ToShortLocalDateTime, ToShortLocalTime } from "../../config/time"
import { monoEndpointURL } from "../../config/urls"
import { ValidValue } from "../../services/IoT"
import { itemKey } from "../../services/AlarmEvents"
import { Box, Stack } from "@mui/system"
import chroma from "chroma-js"
import { MetricData, MetricsQueryResponse } from "../../api/Metrics"

interface Value {
    time: number
    value: number | null
}

export interface Ruler {
    value: number
    label: string
    color: string
    labelOnly?: boolean
}

export interface Channel {
    id: number
    thing: string
    item: string
    label: string
    color?: string
}

export interface MultiChartProps {
    siteID: number
    itemValues: ValidValue[]
    label: (id: number, annotations?: Map<string, string>) => string
    timeOffset: number
    valueUnit: string
    colorPalette: string[]
    rulers?: Ruler[]
    startValue?: number
    endValue?: number
}
const minDataInterval = 60 * Second // Metrics are stored every 60s.
const metricsDensity = 200
const regexThingName = /_(\d+)$/
const undefinedValue = "- -"

const areThingsEqual = (arrayA: { thing: string }[], arrayB: { thing: string }[]): boolean => {
    if (arrayA.length !== arrayB.length) {
        return false
    }

    const setA = new Set(arrayA.map((item) => item.thing))
    return arrayB.every((item) => setA.has(item.thing))
}

const validValuesToChannels = (
    itemValues: ValidValue[],
    label: (id: number, annotations?: Map<string, string>) => string,
    colorPalette: string[]
): Channel[] => {
    if (itemValues === undefined) {
        return []
    }

    const colors = chroma.scale(colorPalette).colors(itemValues.length)

    let colorIndex = 0
    const result = itemValues.reduce((result: Channel[], item) => {
        const matches = item.thing.match(regexThingName)
        if (matches) {
            const thingID = matches[1]
            result.push({
                id: +thingID,
                thing: item.thing,
                item: item.item,
                label: label(+thingID, item.annotations),
                color: colors[colorIndex++],
            })
        }
        return result
    }, [])

    // NOTE: We assume here that if there are multiple items, they will have numbering specified in the thing name.
    if (result.length === 0 && itemValues.length === 1) {
        const item = itemValues[0]
        const thingID = 1
        result.push({
            id: thingID,
            thing: item.thing,
            item: item.item,
            label: label(thingID, item.annotations),
            color: colors[colorIndex],
        })
    }
    return result
}

const createQueryURL = (siteID: number, thing: string[], item: string, dataInterval: number, timeOffset: number) => {
    const startTime = Math.floor(Before(timeOffset) / Second)
    const thingsQuery = thing.map((t) => `thing=${encodeURIComponent(t)}`).join("&")
    const step = dataInterval / Second

    const queryParams = new URLSearchParams({
        start: startTime.toString(),
        item: item,
        step: step.toString(),
    })
    return monoEndpointURL(`sites/${siteID}/metrics/query_range?${queryParams.toString()}&${thingsQuery}`)
}

const processData = (data: Value[], dataInterval: number) => {
    let previousTime: number | null = null
    const processedData: Value[] = []

    data.forEach((point) => {
        const currentTime = point.time

        if (previousTime !== null && currentTime - previousTime > dataInterval * 1.5) {
            processedData.push({ time: currentTime, value: null })
        }

        processedData.push(point)
        previousTime = currentTime
    })

    return processedData
}

export function NewMultiChart(props: MultiChartProps) {
    const { siteID, itemValues, label, timeOffset, valueUnit, colorPalette, rulers, startValue, endValue } = props

    const [data, setData] = useState(new Map<string, Value[]>())
    const [channels, setChannels] = useState<Channel[]>([])
    const [valueStream, setValueStream] = useState(new Map<string, Value>())
    const things = useMemo(() => channels.map((channel) => channel.thing), [channels])
    const item = itemValues[0]?.item
    const dataInterval = Math.max(Math.floor(timeOffset / metricsDensity), minDataInterval)

    const snackbar = useSnackbar()
    const theme = useTheme()

    useEffect(() => {
        if (areThingsEqual(channels, itemValues)) {
            return
        }

        setChannels(validValuesToChannels(itemValues, label, colorPalette))
    }, [itemValues, label, channels, colorPalette])

    useEffect(() => {
        setValueStream((prev) => {
            const newValues = new Map<string, Value>(prev)
            itemValues.forEach((i) => {
                const v = i.value?.number
                if (v === undefined) {
                    return
                }
                newValues.set(itemKey(i.thing, i.item), { time: i.timestamp.getTime(), value: v })
            })

            return newValues
        })
    }, [itemValues])

    const toValues = ({ values }: MetricData): Value[] =>
        values.map(([time, value]) => ({
            time: time * Second,
            value: +value,
        }))

    useEffect(() => {
        if (things.length === 0) {
            return
        }

        const newData = new Map<string, Value[]>()
        http<MetricsQueryResponse>(
            "Getting metrics",
            createQueryURL(siteID, things, item, dataInterval, timeOffset),
            snackbar
        )
            .then((result) => {
                result.data?.result.forEach((data) => {
                    const key = itemKey(data.metric.thing, item)
                    const values = processData(toValues(data), dataInterval)

                    const prevValues = newData.get(key) || []
                    newData.set(key, [...prevValues, ...values])
                })
            })
            .catch((e) => {
                console.error(`Error fetching data:`, e)
            })
            .finally(() => {
                setData(newData)
            })
    }, [snackbar, siteID, timeOffset, item, things, dataInterval])

    const allValues: number[] = useMemo(
        () =>
            data
                ? Array.from(data.values()).flatMap((series) =>
                      series.map((v) => v.value).filter((value): value is number => value !== null)
                  )
                : [],
        [data]
    )

    const allTimes = useMemo(
        () => (data ? Array.from(data.values()).flatMap((series) => series.map((v) => v.time)) : []),
        [data]
    )
    const allRulers = useMemo(() => (rulers ? rulers.map((r) => r.value) : []), [rulers])
    const timeRange = useMemo(
        () => [Math.min(...allTimes, new Date().getTime() - timeOffset), Math.max(...allTimes, new Date().getTime())],
        [allTimes, timeOffset]
    )
    const valueRange = useMemo(
        () => [
            startValue !== undefined
                ? Math.floor(Math.min(startValue, ...allValues, ...allRulers))
                : Math.floor(Math.min(...allValues, ...allRulers)),
            endValue !== undefined
                ? Math.ceil(Math.max(endValue, ...allValues, ...allRulers))
                : Math.ceil(Math.max(...allValues, ...allRulers)),
        ],
        [allValues, allRulers, startValue, endValue]
    )

    const valueFormatter = useCallback((s: any) => (+s).toFixed(2) + " " + valueUnit, [valueUnit])

    const renderValue = useCallback(
        (key: string) => {
            const value = valueStream.get(key)?.value
            return (value ?? undefinedValue) + valueUnit
        },
        [valueStream, valueUnit]
    )

    return (
        <>
            {channels.map((channel) => (
                <Grid item xs={12} sm={6} md={3} lg={2} key={channel.id}>
                    <Stack sx={{ backgroundColor: theme.palette.background.paper }} p={1}>
                        <Typography>{channel.label}</Typography>
                        <Typography color={channel.color}>
                            {renderValue(itemKey(channel.thing, channel.item))}
                        </Typography>
                    </Stack>
                </Grid>
            ))}

            <Grid item xs={12}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        width: "100%",
                        height: {
                            xs: "150px",
                            sm: "200px",
                            md: "250px",
                            lg: "300px",
                        },
                    }}
                    p={1}
                >
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart margin={{ top: 20, right: 20, left: 5, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="1 4" opacity={0.2} />
                            <Tooltip
                                isAnimationActive={false}
                                contentStyle={{
                                    backgroundColor: theme.palette.grey[800],
                                    color: theme.palette.grey[50],
                                }}
                                labelFormatter={ToShortLocalDateTime}
                                formatter={valueFormatter}
                                cursor={false}
                            />
                            <XAxis
                                dataKey="time"
                                fontSize="small"
                                scale="time"
                                tickFormatter={ToShortLocalTime}
                                type="number"
                                domain={timeRange}
                                allowDuplicatedCategory={false}
                                interval="preserveStartEnd"
                            />
                            <YAxis
                                unit={" " + valueUnit}
                                fontSize="small"
                                width={55}
                                dataKey="value"
                                domain={valueRange}
                                scale="linear"
                            />
                            {channels.map((channel) => {
                                const series = data?.get(itemKey(channel.thing, channel.item))
                                if (!series || series.length === 0) {
                                    return null
                                }
                                return (
                                    <Line
                                        key={`line-${channel.thing}`}
                                        isAnimationActive={false}
                                        type="monotone"
                                        dataKey="value"
                                        data={series}
                                        name={channel.label}
                                        stroke={channel.color}
                                        opacity={1}
                                        dot={false}
                                        strokeWidth={2}
                                        scale="linear"
                                        allowReorder="yes"
                                        xAxisId={0}
                                        yAxisId={0}
                                        connectNulls={false}
                                    />
                                )
                            })}
                            {rulers &&
                                rulers.map((r) => (
                                    <ReferenceLine
                                        key={r.label}
                                        y={r.value}
                                        stroke={r.labelOnly ? "transparent" : r.color}
                                        strokeDasharray="4 1"
                                    >
                                        <Label fontSize="0.7em" value={r.label} fill={r.color} position="top" />
                                    </ReferenceLine>
                                ))}
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        </>
    )
}
