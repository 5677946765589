import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Day, Hour } from "../../config/time"

export interface Range {
    label: string
    tooltip: string
    millis: number
}

export const FourWeeksRange = {
    label: "statistics.range_weeks_4",
    tooltip: "statistics.range_weeks_4_tooltip",
    millis: 28 * Day,
}
export const OneWeekRange = {
    label: "statistics.range_week",
    tooltip: "statistics.range_week_tooltip",
    millis: 7 * Day,
}
export const ThreeDayRange = {
    label: "statistics.range_days_3",
    tooltip: "statistics.range_days_3_tooltip",
    millis: 3 * Day,
}
export const OneDayRange = {
    label: "statistics.range_day",
    tooltip: "statistics.range_day_tooltip",
    millis: Day,
}
export const SixHoursRange = {
    label: "statistics.range_hours_6",
    tooltip: "statistics.range_hours_6_tooltip",
    millis: 6 * Hour,
}
export const OneHourRange = {
    label: "statistics.range_hour",
    tooltip: "statistics.range_hour_tooltip",
    millis: Hour,
}

const shortRanges: Range[] = [OneWeekRange, ThreeDayRange, OneDayRange, SixHoursRange, OneHourRange]
const longRanges: Range[] = [FourWeeksRange, OneWeekRange, ThreeDayRange, OneDayRange, SixHoursRange, OneHourRange]

export interface RangeChoiceProps {
    value: Range
    long?: boolean
    onChange: (range: Range) => void
}

export const RangeChoice = (props: RangeChoiceProps) => {
    const { value, long, onChange } = props
    const { t } = useTranslation()
    const ranges = long ? longRanges : shortRanges

    return (
        <ToggleButtonGroup value={value} exclusive={true} onChange={(_, v) => v && onChange(v)}>
            {ranges.map((r) => (
                <Tooltip title={t(r.tooltip)}>
                    <ToggleButton key={r.label} sx={{ padding: "0.5em" }} size="small" value={r} selected={r === value}>
                        <Typography variant="caption">{t(r.label)}</Typography>
                    </ToggleButton>
                </Tooltip>
            ))}
        </ToggleButtonGroup>
    )
}
